import { Italic } from '@tiptap/extension-italic'
import { Editor } from '@tiptap/vue-2';
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';

export default Italic.extend({
  menuBtnView: (editor: Editor) => {
    return {                   
      component: CommandButton,                                              
      componentProps: {                                                      
        command: () => editor.chain().focus().toggleItalic().run(),   
        isActive: editor.isActive('italic'),
        icon: 'italic',
        tooltip: 'Italic',
      },
    };
  }
})

import { OrderedList } from '@tiptap/extension-ordered-list'
import { Editor } from '@tiptap/vue-2';
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';

export default OrderedList.extend({
  menuBtnView: (editor: Editor) => {
    return {                   
      component: CommandButton,                                              
      componentProps: {                                                      
        command: () => editor.chain().focus().toggleOrderedList().run(),   
        isActive: editor.isActive('orderedList'),
        icon: 'list-ol',
        tooltip: 'Ordered List',
      },
    };
  }
})

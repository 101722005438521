import { History } from '@tiptap/extension-history'
import { Editor } from '@tiptap/vue-2'
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';

export default History.extend({
  menuBtnView (editor: Editor) {
    return [
      {
        component: CommandButton,
        componentProps: {
          command: () => editor.commands.undo(),
          icon: 'undo',
          tooltip: 'Undo',
        },
      },
      {
        component: CommandButton,
        componentProps: {
          command: () => editor.commands.redo(),
          icon: 'redo',
          tooltip: 'Redo',
        },
      },
    ];
  }
})

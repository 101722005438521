import { Extension } from '@tiptap/core';
import { Editor } from '@tiptap/vue-2';
import { selectAll } from 'prosemirror-commands';
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    selectAll: {
      /**
       * Select all
       */
      selectAll: () => ReturnType,
    }
  }
}

export default Extension.create({
  name: 'selectAll',
  addCommands() {
    return {
      selectAll: () => ({ state, dispatch }) => {
        selectAll(state, dispatch)
        return true 
      }
    }
  },
  menuBtnView (editor: Editor) {
    return {
      component: CommandButton,
      componentProps: {
        command: () => editor.commands.selectAll(),
        icon: 'regular/object-group',
        tooltip: 'Select All',
      },
    };
  }
})

 

import { provide, inject, Ref } from 'vue'

export const HtmlProviderKey = Symbol()

interface HtmlProperties {
  enableTooltip: Ref<boolean>,
  fullscreen: Ref<boolean>
}

export const provideHtmlProperties = (properties: HtmlProperties) => {
  provide(HtmlProviderKey, properties)
}

export const getHtmlProperties = () => {
  const injection = inject<HtmlProperties>(HtmlProviderKey)
  if (!injection) throw Error('No injection data for html provided')
  return injection
}

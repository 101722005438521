
  import { Editor } from '@tiptap/vue-2'
  import { DEFAULT_FONT_TYPE_MAP, findActiveFontType } from '../utils/font_type';
  import { isPlainObject } from '../utils/shared';
  import {defineComponent, computed} from "vue"
  import {getHtmlProperties} from "../html.utils"
  import CommandButton from "./CommandButton.vue"

  interface Props {
    editor: Editor
  }

  export default defineComponent({
    components: {
      CommandButton
    },
    props: {
      editor: {
        type: Object,
        required: true
      }
    },
    setup(props: Props) {
      const htmlProperties = getHtmlProperties()
      const enableTooltip = computed(() => htmlProperties.enableTooltip.value)

      const fontTypes = computed(() => {
        return props.editor.extensionManager.extensions.find(e => e.name === 'fontFamily')?.options.availableFonts
      })

      const activeFontType = computed(() => findActiveFontType(props.editor.state))

      const toggleFontType = (name: string) => {
        const typeToToggle = name === activeFontType.value ? '' : name
        props.editor.commands.setFontFamily(typeToToggle)
      }

      return {
        enableTooltip,
        activeFontType,
        toggleFontType,
        fontTypes
      }
    }
  })

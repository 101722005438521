import { Extension } from '@tiptap/core';
import { Editor } from '@tiptap/vue-2';
import { setTextLineHeight } from '../utils/line_height';
import LineHeightDropdown from '@/components/shared/form/elements/html/MenuCommands/LineHeightDropdown.vue';

export interface LineHeightOptions {
  lineHeights: string[]
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lineHeight: {
      /**
       * Set the line height attribute
       */
      setLineHeight: (lineHeight: string) => ReturnType,
    }
  }
} 

export default Extension.create<LineHeightOptions>({
  name: 'lineHeight',
  addOptions() {
    return {
      lineHeights: [
        '100%',
        '115%',
        '150%',
        '200%',
        '250%',
        '300%',
      ]
    }
  },
  addCommands() {
    return {
      setLineHeight: (lineHeight: string) => ({ tr, state, dispatch }) => {
        const selection = state.selection
        tr = tr.setSelection(selection)
        tr = setTextLineHeight(tr, lineHeight)

        if (tr.docChanged) {
          dispatch && dispatch(tr)
          return true
        }
        return false
      }
    }
  },
  menuBtnView (editor: Editor) {
    return {
      component: LineHeightDropdown,
      componentProps: {
        editor,
      },
    };
  }
})


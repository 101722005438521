import { BulletList } from '@tiptap/extension-bullet-list'
import { Editor } from '@tiptap/vue-2';
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';

export default BulletList.extend({
  menuBtnView: (editor: Editor) => {
    return {                   
      component: CommandButton,                                              
      componentProps: {                                                      
        command: () => editor.chain().focus().toggleBulletList().run(),   
        isActive: editor.isActive('bulletList'),
        icon: 'list-ul',
        tooltip: 'Bullet List',
      },
    };
  }
})

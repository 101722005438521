import { EditorState } from 'prosemirror-state';

export function isTextAlignActive (state: EditorState, alignment: string): boolean {
  const { selection, doc } = state;
  const { from, to } = selection;

  let keepLooking = true;
  let active = false;

  doc.nodesBetween(from, to, (node) => {
    if (keepLooking && node.attrs.textAlign === alignment) {
      keepLooking = false;
      active = true;
    }
    return keepLooking;
  });

  return active;
}


  import { defineComponent, ref, getCurrentInstance } from "vue"
  import HtmlElement from '@/components/shared/form/elements/html/HtmlElement.vue'
  import { Household } from "@/models/households"
  import { shortDate } from "@/utils/helper"

  interface Props { 
    householdId: string,
    householdPostingBody: string
  }

  export default defineComponent({
    name: 'HouseholdPostingBodyEditor',
    components: {
      HtmlElement,
    },
    props: {
      householdId: {
        type: String,
        required: true
      },
      householdPostingBody: {
        type: String,
        default: ''
      },
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const postingBody = ref(props.householdPostingBody)

      const savePostingBody = () => {
        root.$store.dispatch('households/edit', { id: props.householdId, body: { attributes: { postingBody: postingBody.value } } })
      }

      return {
        postingBody,
        savePostingBody
      }
    }
  })

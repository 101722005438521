import { Bold } from '@tiptap/extension-bold'
import { Editor } from '@tiptap/vue-2';
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';

export default Bold.extend({
  menuBtnView: (editor: Editor) => {
    return {                   
      component: CommandButton,                                              
      componentProps: {                                                      
        command: () => editor.chain().focus().toggleBold().run(),   
        isActive: editor.isActive('bold'),
        icon: 'bold',                                                                                                                                                        
        tooltip: 'Bold',                        
      },
    };
  }
})


  import { getCurrentInstance } from 'vue';
  import { Editor, EditorContent, Extension } from '@tiptap/vue-2';
  import Text from '@tiptap/extension-text'
  import TextStyle from '@tiptap/extension-text-style'
  import HardBreak from '@tiptap/extension-hard-break'
  import Placeholder from '@tiptap/extension-placeholder';
  import { Node as ProsemirrorNode } from 'prosemirror-model';
  import { ContentAttributes } from './extensions/content_attributes';
  import { EVENTS } from './constants';
  import { defineComponent, ref, computed, watch, onBeforeUnmount } from "vue"
  import MenuBar from "./MenuBar/index.vue"
  //import MenuBubble from "./MenuBubble/index.vue"

  import {
    Bold,
    BulletList,
    Doc,
//    FontSize,
    FontType,
    Heading,
    History,
    Italic,
    ListItem,
    OrderedList,
    Paragraph,
    Strike,
    TextAlign,
    Underline
  } from "./extensions"
  import { provideHtmlProperties } from "./html.utils"

  const COMMON_EMIT_EVENTS: EVENTS[] = [
    EVENTS.TRANSACTION,
    EVENTS.FOCUS,
    EVENTS.BLUR,
    EVENTS.PASTE,
    EVENTS.DROP,
  ];

  interface Props {
    elementValue: string,
    placeholder: string,
    editorProperties: any,
    output: string,
    readonly: boolean,
    spellcheck: boolean,
    tooltip: boolean,
    showMenubar: boolean,
    charCounterCount: boolean
  }

  export default defineComponent({
    model: {
      prop: 'elementValue',
      event: 'update:elementValue'
    },
    components: {
      EditorContent,
      MenuBar,
      //MenuBubble
    },
    props: {
      elementValue: {
        type: String
      },
      placeholder: {
        type: String,
        default: ''
      },
      editorProperties: {
        type: Object,
        default: () => ({
          attributes: { class: 'ProseMirror p-3 min-height' }
        })
      },
      output: {
        type: String,
        default: 'html',
        validator: (output: string) => {
          return ['html', 'json'].includes(output)
        }
      },
      readonly: {
        type: Boolean,
        default: false
      },
      spellcheck: {
        type: Boolean,
        default: true
      },
      tooltip: {
        type: Boolean,
        default: true
      },
      editorClass: {
        type: [String, Array, Object],
        default: undefined
      },
      editorContentClass: {
        type: [String, Array, Object],
        default: undefined
      },
      editorMenubarClass: {
        type: [String, Array, Object],
        default: undefined
      },
      editorBubbleMenuClass: {
        type: [String, Array, Object],
        default: undefined
      },
      editorFooterClass: {
        type: [String, Array, Object],
        default: undefined
      },
      menuBubbleOptions: {
        type: Object,
        default: () => ({})
      },
      charCounterCount: {
        type: Boolean,
        default: true
      },
      showMenubar: {
        type: Boolean,
        default: true
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const generateExtensions = () => {
        const extensions: any[] = [
          Doc,
          Text,
          Paragraph,
          Heading,
          TextStyle,
          Bold,
          HardBreak,
          Italic,
          Strike,
          Underline,
//          //new FontSize(),
          FontType,
          TextAlign,
          BulletList,
          OrderedList,
          History,
          ListItem,
          Placeholder.configure({
            placeholder: props.placeholder
          }),
          ContentAttributes.configure({
            spellcheck: props.spellcheck
          })
        ]

        return extensions
      }

      const extensions = generateExtensions()
      const editorValue = new Editor({
        editorProps: props.editorProperties,
        editable: !props.readonly,
        extensions,
        onUpdate({ editor }) {
          // Replace double <br> with a new paragraph so </p><p>
          const html = editor.getHTML().replace(/<br><br>/g, "</p><p>")
          emitCurrentHtml(html)
        },
        content: props.elementValue
      })
      const editor = ref(editorValue)
      const cmTextArea = ref()

      const isFullscreen = ref(false)

      const characters = computed(() => {
        if (!editor.value) return 0
        return editor.value.state.doc.textContent.length
      })

      const showFooter = computed(() => props.charCounterCount)

      const emitCurrentHtml = (html: string) => {
        // If no text is entered return null for v-model
        if (html === "<p></p>") {
          return emit("update:elementValue", null)
        }
        emit('update:elementValue', html)
      }

      watch(() => props.readonly, (newValue) => {
        if (editor.value) {
          editor.value.setOptions({
            editable: newValue
          })
        }
      })

      onBeforeUnmount(() => {
        // Always destroy your editor instance when it's no longer needed
        if (editor.value) editor.value.destroy()
      })

      const tooltip = computed(() => props.tooltip)
      provideHtmlProperties({ enableTooltip: tooltip, fullscreen: isFullscreen })

      return {
        editor,
        cmTextArea,
        showFooter,
        characters,
        isFullscreen
      }
    }
  })

import { Editor } from '@tiptap/vue-2'
import { FontFamily } from '@tiptap/extension-font-family'
import FontTypeDropdown from '@/components/shared/form/elements/html/MenuCommands/FontTypeDropdown.vue';

export default FontFamily.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      availableFonts: [
        'Arial',
        'Arial Black',
        'Georgia',
        'Impact',
        'Tahoma',
        'Times New Roman',
        'Verdana',
        'Courier New',
        'Lucida Console',
        'Monaco',
        'monospace',
      ]
    }
  },
  menuBtnView (editor: Editor) {
    return {
      component: FontTypeDropdown,
      componentProps: {
        editor,
      },
    };
  }
})

import { Strike } from '@tiptap/extension-strike'
import { Editor } from '@tiptap/vue-2';
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';

export default Strike.extend({
  menuBtnView: (editor: Editor) => {
    return {                   
      component: CommandButton,                                              
      componentProps: {                                                      
        command: () => editor.chain().focus().toggleStrike().run(),   
        isActive: editor.isActive('strike'),
        icon: 'strikethrough',
        tooltip: 'Strike through'                        
      },
    };
  }
})


  import { Editor } from '@tiptap/vue-2';
  import { isLineHeightActive as lineHeightActive } from '../utils/line_height';
  import {defineComponent, computed} from "vue"
  import {getHtmlProperties} from "../html.utils"
  import CommandButton from "./CommandButton.vue"

  interface Props {
    editor: Editor
  }

  export default defineComponent({
    components: {
      CommandButton
    },
    props: {
      editor: {
        type: Object,
        required: true
      },
    },
    setup(props: Props) {
      const htmlProperties = getHtmlProperties()
      const enableTooltip = computed(() => htmlProperties.enableTooltip.value)

      const lineHeights = computed(() => props.editor.extensionManager.extensions.find(e => e.name === 'lineHeight')?.options.lineHeights)
      const isLineHeightActive = (lineHeight: string) => lineHeightActive(props.editor.state, lineHeight)
      return {
        enableTooltip,
        lineHeights,
        isLineHeightActive
      }
    }
  })

export const ALIGN_PATTERN: RegExp = new RegExp('(left|center|right|justify)');

export const LINE_HEIGHT_100 = 1.7;
export const DEFAULT_LINE_HEIGHT = '100%';

export const enum EVENTS {
  INIT = 'init',
  TRANSACTION = 'transaction',
  FOCUS = 'focus',
  BLUR = 'blur',
  PASTE = 'paste',
  DROP = 'drop',
  UPDATE = 'update',
};

import { Extension } from '@tiptap/core';
import { Plugin } from 'prosemirror-state';

export interface ContentAttributesOptions {
  spellcheck: boolean
}

export const ContentAttributes = Extension.create<ContentAttributesOptions>({
  name: 'content_attributes',
  addOptions() {
    return {
      spellcheck: true
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          attributes: {
            spellcheck: String(this.options.spellcheck),
          },
        },
      })
    ]
  }
})

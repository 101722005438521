import { Editor } from '@tiptap/vue-2'
import { TextAlign } from '@tiptap/extension-text-align'
import { MenuBtnComponentOptions } from 'html-element'
import { isTextAlignActive } from '../utils/text_align'
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';
import { VueConstructor } from 'vue';


export default TextAlign.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      types: ['heading', 'paragraph']
    }
  },
  menuBtnView: (editor: Editor) => {
    return ['left', 'center', 'right', 'justify'].reduce((views: Array<MenuBtnComponentOptions>, alignment: string) => {
      const isActive = alignment === 'left'
        ? false
        : isTextAlignActive(editor.state, alignment);

      return views.concat({
        component: CommandButton as unknown as VueConstructor,
        componentProps: {
          isActive,
          command: () => editor.commands.setTextAlign(alignment),
          icon: `align-${alignment}`,
          tooltip: `Text align ${alignment}`,
        },
      });
    }, []);
  }
})

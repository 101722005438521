
  import { Editor } from '@tiptap/vue-2';
  import { MenuBtnViewType } from "html-element"
  import { defineComponent, computed } from "vue"
  import { getHtmlProperties } from "../html.utils"

  interface Props {
    editor: Editor
  }
  export default defineComponent({
    props: {
      editor: {
        type: Editor,
        required: true
      }
    },
    setup(props: Props) {
      const htmlProperties = getHtmlProperties()
      const enableTooltip = computed(() => htmlProperties.enableTooltip.value)
      const extensionManager = computed(() => props.editor.extensionManager)
      const generateCommandButtonComponentSpecs: () => MenuBtnViewType[] = () => {
        return extensionManager.value.extensions.reduce <MenuBtnViewType[]>((acc, extension) => {
          if (extension.options.menubar === false) return acc;
          if (!extension.config.menuBtnView || typeof extension.config.menuBtnView !== 'function') return acc;
          const menuBtnComponentSpec = extension.config.menuBtnView(props.editor);
          if (Array.isArray(menuBtnComponentSpec)) {
            return [
              ...acc,
              ...menuBtnComponentSpec,
            ];
          }
          return [
            ...acc,
            menuBtnComponentSpec,
          ];
        }, []);
      }

      return {
        enableTooltip,
        generateCommandButtonComponentSpecs
      }
    }
  })

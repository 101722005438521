import { Document } from '@tiptap/extension-document'

export interface DocumentOptions {
  title: boolean
}

export default Document.extend<DocumentOptions>({
  addOptions() {
    return {
      title: false
    }
  },
  extendNodeSchema() {
    return {
      content: !!this.options?.title ? 'title block+' : 'block+'
    }
  }
})

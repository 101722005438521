import { Underline } from '@tiptap/extension-underline'
import { Editor } from '@tiptap/vue-2';
import CommandButton from '@/components/shared/form/elements/html/MenuCommands/CommandButton.vue';

export default Underline.extend({
  menuBtnView: (editor: Editor) => {
    return {                   
      component: CommandButton,                                              
      componentProps: {                                                      
        command: () => editor.chain().focus().toggleUnderline().run(),   
        isActive: editor.isActive('underline'),
        icon: 'underline',
        tooltip: 'Underline',                        
      },
    };
  }
})

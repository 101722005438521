
  import { AnyExtension } from '@tiptap/core'
  import { Editor } from '@tiptap/vue-2'
  import Heading from '../extensions/heading'
  import { isHeadingActive as headingActive } from '../utils/heading';
  import {defineComponent, computed} from "vue"
  import {getHtmlProperties} from "../html.utils"
  import CommandButton from "./CommandButton.vue"

  interface Props {
    editor: Editor
  }

  export default defineComponent({
    components: {
      CommandButton
    },
    props: {
      editor: {
        type: Object,
        required: true
      }
    },
    setup(props: Props) {
      const htmlProperties = getHtmlProperties()
      const enableTooltip = computed(() => htmlProperties.enableTooltip.value)
      const levels = computed(() => {
        const extensions = props.editor.extensionManager.extensions
        const headingOptions = extensions.find(e => e.name === 'heading')?.options
        return headingOptions?.levels ?? []
      })
      const isHeadingActive = (level: number) => headingActive(props.editor.state, level)

      return {
        enableTooltip,
        levels,
        isHeadingActive,
      }
    }
  })

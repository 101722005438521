
  import { noop } from '../utils/shared';
  import {defineComponent} from "vue"

  interface Props {
    icon: string,
    isActive: boolean,
    tooltip: string,
    enableTooltip: boolean,
    command: Function,
    readonly: boolean
  }

  export default defineComponent({
    props: {
      icon: {
        type: String,
        required: true
      },
      isActive: {
        type: Boolean,
        default: false
      },
      tooltip: {
        type: String,
        required: true
      },
      enableTooltip: {
        type: Boolean,
        required: true
      },
      command: {
        type: Function,
        default: noop
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    setup(props: Props) {
      const onClick = () => {
        if (!props.readonly) {
          props.command()
        }
      }

      const menuButtonOptions = {
        scale: 1.3,
        variant: "light"
      }

      return {
        onClick,
        menuButtonOptions
      }
    }
  })
